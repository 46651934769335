<template>
  <div id="page-fixed-bearings">
      <AppRouterTabNav :items="tabsItems" />
      <div class="contentbox">
        <router-view />
      </div>
  </div>
</template>

<script>
import AppRouterTabNav from '@/components/app/AppRouterTabNav.vue';

export default {
  components: {
    AppRouterTabNav,
  },
  data() {
    return {
      tabsItems: [
        {
          label: this.$i18n.t('tabs.tabDimension'),
          link: '/verformungslager/bemessung',
        },
        {
          label: this.$i18n.t('tabs.tabEccentricity'),
          link: '/verformungslager/exzentritaet',
        },
        {
          label: this.$i18n.t('tabs.tabEdgeDistanceDIN'),
          link: '/verformungslager/randabstand-din',
        },
        {
          label: this.$i18n.t('tabs.tabEdgeDistanceEC'),
          link: '/verformungslager/randabstand-ec',
        },
        {
          label: this.$i18n.t('tabs.tabEdgeContact'),
          link: '/verformungslager/kantenkontakt',
        },
        {
          label: this.$i18n.t('tabs.tabCrossTraction'),
          link: '/verformungslager/querzugkraft',
        },
        {
          label: this.$i18n.t('tabs.tabHoles'),
          link: '/verformungslager/bohrung',
        },
        {
          label: this.$i18n.t('tabs.tabTwisting'),
          link: '/verformungslager/auflagerverdrehung',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
